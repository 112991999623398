import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

const config = {
    apiKey: "AIzaSyAqLa-L_4FjcgnFZbLXJ6eQDVyxz58eWlU",
    authDomain: "banca-gamer.firebaseapp.com",
    projectId: "banca-gamer",
    storageBucket: "banca-gamer.appspot.com",
    messagingSenderId: "393819843065",
    appId: "1:393819843065:web:250b026746c44a13087528",
    measurementId: "G-F9XB5JMYBZ",
  };
  const app = initializeApp(config);
  export const db = getFirestore(app);
  const analytics = getAnalytics(app);



