import "./Dialog.css";
import imageLogo from "../../assets/img/logo-banca-gamer-resize.png";
import imageLogoEn from "../../assets/img/logo-gamer-loan.png";
import { useContext, useRef, useState } from "react";
import { ButtonBancaGamer } from "../Button/Button";
import { sendEmail } from "../../utils/SendMail";
import { validateForm } from "../../utils/Regex";
import { Loader } from "../Loader/Loader";
import { useNavigate } from "react-router-dom";
import { languageContext, lenguaje } from "../../Context/Context";

type props = {
  openDialog: Boolean;
  closeDialog: Function;
  juego: string;
  valor: number;
};
export const Dialog = ({ openDialog, closeDialog, juego, valor }: props) => {
  const { language } = useContext(languageContext);

  const containerRef = useRef<HTMLDivElement | null>(null);
  const backRef = useRef<HTMLDivElement | null>(null);
  const formRef = useRef<HTMLFormElement | null>(null);
  const correoRef = useRef<HTMLInputElement | null>(null);
  const [correo, setCorreo] = useState("");
  const [nombre, setNombre] = useState("");
  const [cedula, setCedula] = useState("");
  const [nickname, setNickName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [emailCorrecto, setEmailCorrecto] = useState(true);
  const [aceptaTyC, setAceptaTyC] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    const esValido = validateForm(correo);
    setEmailCorrecto(esValido);
    if (esValido) {
      await sendEmail(
        language,
        correo,
        nombre,
        cedula,
        nickname,
        juego,
        valor,
        handleClick
      ).then(() =>
        setTimeout(() => {
          navigate("/Registrado");
        }, 700)
      );
    }
  };

  const handleClick = (e: any) => {
    if (containerRef.current) {
      containerRef.current.classList.add("close");
      backRef.current?.classList.add("close-fade");
      setIsLoading(false);
    }
    setTimeout(() => {
      setIsLoading(false);
      backRef.current?.classList.remove("close-fade");
      containerRef.current?.classList.remove("close");
    }, 600);
    setTimeout(() => {
      closeDialog(false);
    }, 700);
    // e.preventDefault();
    // navigate('/');
  };

  const handleChange = () => {
    setAceptaTyC(!aceptaTyC);
  };

  return !openDialog ? null : (
    <>
      <div className="fade-back" ref={backRef}></div>
      <div className="dialog-container" ref={containerRef}>
        <div className="video-dialog-back">
          <video controls={false} autoPlay={true} loop muted>
            <source
              src="https://astrid-lab.com/public/fondo5.mp4"
              type="video/mp4"
            ></source>
          </video>
        </div>
        <div className="inner-container">
          <img
            src={language === lenguaje.es ? imageLogo : imageLogoEn}
            alt=""
            className="title-logo"
          />
          <button
            className="material-symbols-outlined close-button"
            onClick={handleClick}
          >
            close
          </button>
          {language === lenguaje.es ? (
            <p>
              ESTÁS A PUNTO DE HACERLE UN UPGRADE A TUS FINANZAS,
              <br /> PERO ANTES NECESITAREMOS UNOS DATOS MÁS:
            </p>
          ) : (
            <p>
              YOU ARE CLOSE TO MAKING AN UPGRADE TO YOUR FINANCES,
              <br />
              BUT FIRST WE WILL NEED A FEW MORE INFORMATION:
            </p>
          )}

          <form name="user" ref={formRef} onSubmit={handleSubmit}>
            <div className="row">
              {/* <span className="material-symbols-outlined icon">face</span> */}
              <label htmlFor="name">
                {language === lenguaje.es ? "Nombre:" : "Name:"}
              </label>
              <input
                type="text"
                name="name"
                id="name"
                value={nombre}
                onChange={(e) => setNombre(e.target.value)}
                className={
                  emailCorrecto ? "dialog-input" : "dialog-input error"
                }
              />
            </div>
            <div className="row">
              {/* <span className="material-symbols-outlined icon">face</span> */}
              <label htmlFor="nickname">
                {language === lenguaje.es ? "Usuario:" : "User:"}
              </label>
              <input
                type="text"
                name="nickname"
                id="nickname"
                value={nickname}
                onChange={(e) => setNickName(e.target.value)}
                className={
                  emailCorrecto ? "dialog-input" : "dialog-input error"
                }
              />
            </div>

            <div className="row-inputs">
              <div className="row">
                {/* <span className="material-symbols-outlined icon">
                sports_esports
              </span> */}
                <label htmlFor="cuentabp">
                  {language === lenguaje.es ? "Cédula:" : "ID number:"}
                </label>
                <input
                  type="text"
                  name="cuentabp"
                  value={cedula}
                  onChange={(e) => setCedula(e.target.value)}
                  id="cuentabp"
                  className="dialog-input"
                />
              </div>
              <div className="row">
                {/* <span className="material-symbols-outlined icon">mail</span> */}
                <label htmlFor="email">
                  {language === lenguaje.es ? "Correo:" : "Email:"}
                </label>
                <input
                  ref={correoRef}
                  type="email"
                  name="email"
                  id="email"
                  value={correo}
                  onChange={(e) => setCorreo(e.target.value)}
                  // onChange={()=>console.log(correoRef?.current?.value)}
                  className="dialog-input"
                />
              </div>
            </div>
            <div className="row" onClick={handleChange}>
              <div className="bgcheckbox">
                {aceptaTyC ? <span className="checkmark"></span> : null}
              </div>
              <p
                style={{
                  fontSize: "9px",
                  textAlign: "left",
                  letterSpacing: "1px",
                }}
              >
                {language === lenguaje.es
                  ? "Al enviar este formulario autorizas el uso de información para mejorar el servicio y el marketing"
                  : "By submitting this form you authorize the use of information to improve service and marketing"}
              </p>
            </div>
            <div className="row">
              <ButtonBancaGamer
                ruta={""}
                action={() => handleSubmit}
                label={language === lenguaje.es ? "Enviar" : "Send"}
                tipo={aceptaTyC && nombre !== "" && correo !== "" ? 2 : 3}
              ></ButtonBancaGamer>
            </div>
          </form>
        </div>
      </div>
      <Loader isLoading={isLoading} />
    </>
  );
};
