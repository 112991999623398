import { NavBar } from "../../components/NavBar/NavBar";
import { useContext, useEffect, useState } from "react";
import InputText from "../../components/InputText/InputText";
import { Titular } from "../../components/Titular/Titular";
import { ButtonBancaGamer } from "../../components/Button/Button";
import { Dialog } from "../../components/Dialog/Dialog";
import { robloxValores } from "../../data/ValorDeItems";
import { useLocation } from "react-router-dom";
import { languageContext, lenguaje } from "../../Context/Context";
export const Roblox = () => {
  const { language } = useContext(languageContext);
  // Estados calculadora
  const [cotizacion, setCotizacion] = useState(0);
  const [tipoBoton, setTipoBoton] = useState(3);
  const [openDialog, setOpendialog] = useState(false);
  // Estados juego
  const [robux, setRobux] = useState(0);
  const [limitedItems, setLimitedItems] = useState(0);
  const [lettersInName, setLettersInName] = useState(0);
  const [yearsAccount, setYearsAccount] = useState(0);
  const [friendsNumber, setFriendsNumber] = useState(0);
  const [isMale, setIsMale] = useState(true);
  const [icgItems, setIcgItems] = useState(true);
  const nombreJuego = "Roblox";

  let valorDeCuenta =
    robloxValores.inicial +
    robux * robloxValores.robux +
    limitedItems * robloxValores.limitedItems +
    lettersInName * robloxValores.lettersIName +
    yearsAccount * robloxValores.yearsOld +
    friendsNumber;

  const handleClick = () => {
    let val: number = valorDeCuenta / 100;
    val > cotizacion
      ? addAnimation(val)
      : val < cotizacion
        ? substracAnimation(val)
        : console.log("no cambiar");

    if (val > 0) {
      setTipoBoton(2);
    } else {
      setTipoBoton(3);
    }
  };

  // animacion de calculo de valores cuando suma
  const addAnimation = async (resultado: number) => {
    let result = cotizacion;
    const intervalId = setInterval(() => {
      if (result < resultado) {
        result = result + 0.13;
        setCotizacion(result);
      } else {
        clearInterval(intervalId);
        setCotizacion(resultado);
        console.log("Terminado");
      }
    }, 2);
  };
  // animacion de calculo de valores cuando resta
  const substracAnimation = async (resultado: number) => {
    let result = cotizacion;
    const intervalId = setInterval(() => {
      if (result > resultado) {
        result = result - 0.013;
        setCotizacion(result);
      } else {
        clearInterval(intervalId);
        setCotizacion(resultado);
        console.log("Terminado");
      }
    }, 2);
  };
  const enviarCuenta = () => {
    setOpendialog(true);
  };

  const location = useLocation();
  const [showScrollDown, setShowScrollDown] = useState(true);
  useEffect(() => {
    const onScroll = () => {
      const percentageScroll =
        ((window.scrollY / window.innerHeight) * 100) / 4;

      if (percentageScroll <= 75) {
        setShowScrollDown(true);
      } else {
        setShowScrollDown(false);
      }
    };
    window.addEventListener("scroll", onScroll);
    const videosCalculadora = document.getElementById("videoCalculadoras");
    if (location.pathname !== "/" && location.pathname !== "/Faqs") {
      videosCalculadora!.style.display = "block";
    }

    return () => {
      if (videosCalculadora !== null) {
        videosCalculadora.style.display = "none";
        window.removeEventListener("scroll", onScroll);
      }
    };
  });
  return (
    <section className="calculadora calculadora-padding">
      <NavBar />
      <div className="form-container">
        <Titular cotizacion={cotizacion} titular={nombreJuego} />
        <span style={{ height: "60px" }}></span>
        <div className="grid">
          <InputText
            etiqueta={language === lenguaje.es ? "de Robux" : "ROBUX"}
            valor={robux}
            setValue={setRobux}
            aumento={100}
          />
          <InputText
            etiqueta={language === lenguaje.es ? "de Items Limitados" : "LIMITED ITEMS"}
            valor={limitedItems}
            setValue={setLimitedItems}
            aumento={1}
          />
          <InputText
            etiqueta={language === lenguaje.es ? "de caracteres en usuario" : "LETTERS IN NAME"}
            valor={lettersInName}
            setValue={setLettersInName}
            aumento={1}
          />
          <InputText
            etiqueta={language === lenguaje.es ? "de años de creación de la cuenta" : "YEARS SINCE ACCOUNT WAS CREATED"}
            valor={yearsAccount}
            setValue={setYearsAccount}
            aumento={1}
          />
          {/* <InputText
            etiqueta={language === lenguaje.es ? "de Friends"}
            valor={friendsNumber}
            setValue={setFriendsNumber}
            aumento={1}
          /> */}

          <div className="input-card">
            <label>{language === lenguaje.es ? "¿Tiene Items UGC en venta?" : "UGC ITEMS ON SALE"}</label>
            <div className="outlined-radio">
              <input
                type="radio"
                id="venta"
                name="Venta"
                value="venta"
                checked={icgItems}
                onChange={() => {
                  setIcgItems(true);
                }}
              />
              <label htmlFor="venta">{language === lenguaje.es ? "Si" : "YES"}</label>
            </div>
            <div className="outlined-radio">
              <input
                type="radio"
                id="noVenta"
                name="NoVenta"
                value="noVenta"
                checked={!icgItems}
                onChange={() => {
                  setIcgItems(false);
                }}
              />
              <label htmlFor="noVenta">{language === lenguaje.es ? "NO" : "NO"}</label>
            </div>
          </div>
        </div>
        <div className="calculadora-buttons">
          <ButtonBancaGamer
            ruta=""
            action={handleClick}
            label={language === lenguaje.es ? "CALCULAR VALOR" : "ESTIMATE NOW"}
            tipo={2}
          />
          <ButtonBancaGamer
            ruta=""
            action={enviarCuenta}
            label={language === lenguaje.es ? "ENVIAR" : "SEND"}
            tipo={tipoBoton}
          />
        </div>
        <div className="footer">COPYRIGHT © 2024 BANCO DEL PACÍFICO</div>
      </div>
      <Dialog openDialog={openDialog} closeDialog={setOpendialog} juego={nombreJuego} valor={cotizacion} />
    </section>
  );
};
