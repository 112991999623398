export const ColorRareza = (rareza: string) => {
    switch (rareza) {
        case 'uncommon':
            return '#1eff00';
        case 'rare':
            return '#0070dd';
        case 'epic':
            return '#a335ee';
        case 'legendary':
            return '#ca9011';
        case 'icon':
            return '#70e2fb';
        case 'marvel':
            return '#d91a27';
        case 'starwars':
            return '#121369';
        case 'dark':
            return '#831aa3';
        default:
            return 'var(--bg-color)';
    }
}