import { useContext, useEffect } from "react";
import { NavBar } from "../../components/NavBar/NavBar";
import "./Registrado.css";
import { Navigate, redirect, useNavigate } from "react-router-dom";
import { languageContext, lenguaje } from "../../Context/Context";



export const Registrado = () => {
  const { language } = useContext(languageContext);

const navigate = useNavigate()  
  useEffect(() => {
    const redirigir = setTimeout(() => {
      navigate("/");
    }, 5000);

    return ()=> clearTimeout(redirigir);
  }, []);
  return (
    <>
      <NavBar />
      <section>
        <div className="registrado-section-container">
          <strong>{language === lenguaje.es ? "¡Gracias por registrarte!" : "Thanks for signing up"}</strong>
          <p>
          {language === lenguaje.es ? 
            "Pronto te enviaremos más información sobre nuestros servicios para Gamers" : "Soon we will send you more information about our services for Gamers"}
          </p>
        </div>
      </section>
    </>
  );
};
