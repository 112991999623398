import { NavBar } from "../../components/NavBar/NavBar";
import { useContext, useEffect, useState } from "react";
import { Titular } from "../../components/Titular/Titular";
import { Row } from "../../components/Row/Row";
import { ButtonBancaGamer } from "../../components/Button/Button";
import { Dialog } from "../../components/Dialog/Dialog";
import { callOfDuty, pokemonGo } from "../../data/ValorDeItems";
import InputText from "../../components/InputText/InputText";
import { useLocation } from "react-router-dom";
import { languageContext, lenguaje } from "../../Context/Context";
export const PokemonGo = () => {
  const { language } = useContext(languageContext);
  // Estados calculadora
  const [cotizacion, setCotizacion] = useState(0);
  const [tipoBoton, setTipoBoton] = useState(3);
  const [openDialog, setOpendialog] = useState(false);
  // Estados juego
  const [numPokemons, setNumPokemons] = useState(0);
  const [level, setLevel] = useState(0);
  const [numShyniPokemons, setNumShyniPokemons] = useState(0);
  const [maxlevelPokemons, setMaxlevelPokemons] = useState(0);
  const [age, setAge] = useState(0);
  const [numLegendaryPokemons, setNumLegendaryPokemons] = useState(0);
  const nombreJuego = "Pokemon Go"
  const valorInicial =
    numPokemons + level + numShyniPokemons + age + numLegendaryPokemons > 0
      ? 100
      : 0;

  let valorDeCuenta =
    numPokemons * pokemonGo.numPokemons +
    level * pokemonGo.level +
    numShyniPokemons * pokemonGo.numShyniPokemons +
    maxlevelPokemons * pokemonGo.maxlevelPokemons +
    age * pokemonGo.age +
    numLegendaryPokemons * pokemonGo.numLegendaryPokemons +
    valorInicial;

  const handleClick = () => {
    let val: number = valorDeCuenta / 100;
    val > cotizacion
      ? addAnimation(val)
      : val < cotizacion
        ? substracAnimation(val)
        : console.log("no cambiar");

    if (val > 0) {
      setTipoBoton(2);
    } else {
      setTipoBoton(3);
    }
  };

  // animacion de calculo de valores cuando suma
  const addAnimation = async (resultado: number) => {
    let result = cotizacion;
    const intervalId = setInterval(() => {
      if (result < resultado) {
        result = result + 0.33;
        setCotizacion(result);
      } else {
        clearInterval(intervalId);
        setCotizacion(resultado);
        console.log("Terminado");
      }
    }, 2);
  };
  // animacion de calculo de valores cuando resta
  const substracAnimation = async (resultado: number) => {
    let result = cotizacion;
    const intervalId = setInterval(() => {
      if (result > resultado) {
        result = result - 0.33;
        setCotizacion(result);
      } else {
        clearInterval(intervalId);
        setCotizacion(resultado);
        console.log("Terminado");
      }
    }, 2);
  };
  const enviarCuenta = () => {
    setOpendialog(true);
  };
  const location = useLocation();
  const [showScrollDown, setShowScrollDown] = useState(true);
  useEffect(() => {
    const onScroll = () => {
      const percentageScroll =
        ((window.scrollY / window.innerHeight) * 100) / 4;

      if (percentageScroll <= 75) {
        setShowScrollDown(true);
      } else {
        setShowScrollDown(false);
      }
    };
    window.addEventListener("scroll", onScroll);
    const videosCalculadora = document.getElementById("videoCalculadoras");
    if (location.pathname !== "/" && location.pathname !== "/Faqs") {
      videosCalculadora!.style.display = "block";
    }

    return () => {
      if (videosCalculadora !== null) {
        videosCalculadora.style.display = "none";
        window.removeEventListener("scroll", onScroll);
      }
    };
  });
  return (
    <>
      <section className="calculadora">
        <NavBar />
        <div className="form-container calculadora-padding">
          <div style={{ height: "100px" }}></div>
          <Titular cotizacion={cotizacion} titular={nombreJuego} />
          <span style={{ height: "60px" }}></span>
          <div className="grid">
            <InputText
              etiqueta={language === lenguaje.es ? "de Pokemons" : "POKEMON"}
              valor={numPokemons}
              setValue={setNumPokemons}
              aumento={1}
            />
            <InputText
              etiqueta={language === lenguaje.es ? "Nivel" : "ACCOUNT LEVEL"}
              valor={level}
              setValue={setLevel}
              aumento={1}
            />
            <InputText
              etiqueta={language === lenguaje.es ? " de Pokemons Shiny" : "SHINY POKEMON"}
              valor={numShyniPokemons}
              setValue={setNumShyniPokemons}
              aumento={1}
            />
            <InputText
              etiqueta={language === lenguaje.es ? "de Pokemons 100% IV" : "100% IV POKEMON"}
              valor={maxlevelPokemons}
              setValue={setMaxlevelPokemons}
              aumento={1}
            />
            <InputText
              etiqueta={language === lenguaje.es ? "Antigüedad de la cuenta" : "ACCOUNT AGE"}
              valor={age}
              setValue={setAge}
              aumento={1}
            />
            <InputText
              etiqueta={language === lenguaje.es ? "de Pokemons Legendarios" : "LEGENDARY POKEMON"}
              valor={numLegendaryPokemons}
              setValue={setNumLegendaryPokemons}
              aumento={1}
            />
          </div>
          <div className="calculadora-buttons">
            <ButtonBancaGamer
              ruta=""
              action={handleClick}
              label={language === lenguaje.es ? "CALCULAR VALOR" : "ESTIMATE NOW"}
              tipo={2}
            />
            <ButtonBancaGamer
              ruta=""
              action={enviarCuenta}
              label={language === lenguaje.es ? "ENVIAR" : "SEND"}
              tipo={tipoBoton}
            />
          </div>
          <div className="footer">COPYRIGHT © 2024 BANCO DEL PACÍFICO</div>
        </div>
        <Dialog openDialog={openDialog} closeDialog={setOpendialog} juego={nombreJuego} valor={cotizacion} />
      </section>
    </>
  );
};
