import './CardItemDestacado.css'
import { CosmeticosFortnite } from "../../types"
import { url } from 'inspector';
import { ColorRareza } from '../../utils/GetColorFortnite';
type props = {
    cosmeticosElegidos: CosmeticosFortnite[];
    remove: Function
}
export const CardItemDestacado = ({cosmeticosElegidos, remove }: props) => {
    
    let items = cosmeticosElegidos.map((elemento, index) => (
  
        <li key={index} className='card-item-destacado' style={{ backgroundColor: ColorRareza(elemento.rarity.value), backgroundImage: `url(${elemento.images.icon})`}} >
            {/* <img src={elemento.images.icon} alt=""/> */}
            <span className='card-item-destacado-title'>
                {elemento.name}
            </span>
            <span  title="Quitar"
          className="icon-button material-symbols-outlined float-button"
          onClick={()=>remove(elemento)}
        >
          remove
        </span>
        </li>
    ));
    return <ul className="card-item-destacado-container">
        {items}
    </ul>
}

