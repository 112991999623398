import { useContext, useEffect } from "react";
import { NavBar } from "../../components/NavBar/NavBar"
import style from "./faqs.module.css"
import { useLocation } from "react-router-dom";
import { languageContext, lenguaje } from "../../Context/Context";

export const Faqs = () => {
    const { language } = useContext(languageContext);

    const location = useLocation();
    useEffect(() => {
        const videoFaq = document.getElementById("videoFaqs");
        if (location.pathname === "/Faqs") {
            videoFaq!.style.display = "block";
        }
        return () => {
            if (videoFaq !== null) {
                videoFaq.style.display = "none";
            }
        };
    });
    return <>

        <NavBar />
        <section className={style.sectionfaq}>
            <div className={style.faqcontainer}>
                <h1 className={style.faqtitle}>FAQS</h1>
                <div>
                    <div>
                        <h2>{language === lenguaje.es ? "¿Cómo me comunico con servicio de atención al cliente?" : "HOW DO I CONTACT CUSTOMER SERVICE?"}</h2>
                        <p>{language === lenguaje.es ? "Puedes comunicarte con nosotros llamando a nuestro número telefónico 3731500 opción 9" : "You can contact us by calling our telephone number 3731500 option 9"}</p>
                    </div>
                    <div>
                        <h2>{language === lenguaje.es ? "¿El valor de mi crédito será el mismo que el de mi cuenta de videojuegos?" : "WILL THE VALUE OF MY LOAN BE THE SAME AS MY VIDEO GAME ACCOUNT?"}</h2>
                        <p>{language === lenguaje.es ? "No necesariamente. El valor del crédito bancario se determina mediante la evaluación de diversos factores por parte de Banco del Pacífico, como el historial crediticio, la gestión de ahorros, entre otros." : "Not necessarily. The value of the bank loan is determined by Banco del Pacífico through the evaluation of various factors such as credit history, savings management, among others."}</p>
                    </div>
                    <div>
                        <h2>{language === lenguaje.es ? "¿PUEDO SOLICITAR ESTE CRÉDITO AL BANCO DEL PACIFICO SIN SER CLIENTE?" : "CAN I APPLY FOR THE LOAN WITHOUT BEING A CUSTOMER OF BANCO DEL PACÍFICO?"}</h2>
                        {language === lenguaje.es ? 
                        <p>No. Debido a que para solicitar el servicio de Banca Gamer necesitas ser previamente un cliente del Banco del Pacífico. Si quieres crear tu primera cuenta con nosotros, hazlo haciendo clic <a href="https://www.bancodelpacifico.com/personas/inicio#" target="_blank" rel="noreferrer">aquí.</a></p>
                        : <p>No. Because to request The Gamer Loan service you need to previously be a client of Banco del Pacífico. If you want to create your first account with us, do so by clicking <a href="https://www.bancodelpacifico.com/personas/inicio#" target="_blank" rel="noreferrer">here.</a></p>}</div>
                </div>
                <div>
                    <div>
                        <h2>{language === lenguaje.es ? "¿TODOS LOS CLIENTES DEL BANCO DEL PACÍFICO PUEDEN SOLICITAR EL CRÉDITO?": "CAN ALL BANCO DEL PACÍFICO CUSTOMERS APPLY FOR THE LOAN?"}</h2>
                        <p>{language === lenguaje.es ? "Así es. El rango de tu PacifiCard, tu antigüedad como cliente del Banco del Pacífico, o incluso si has sido rechazado previamente para préstamos, no son obstáculos para acceder al beneficio de Banca Gamer." : "That's right! The rank of your PacifiCard, your time as a Banco del Pacífico customer, or even if you have previously been rejected for a loan, are not obstacles to access the Gamer Loan benefit"}</p>
                    </div>
                    <div>
                        <h2>{language === lenguaje.es ?  "¿EN CUÁNTO TIEMPO TENDRÉ MI CRÉDITO?" : "IN HOW LONG WILL I GET MY LOAN?"}</h2>
                        <p>{language === lenguaje.es ?  "La verificación de banco puede tardar hasta 72 horas. Pon atención a tu correo electrónico para averiguar si tu solicitud ha sido aprobada." : "The bank’s verification can take up to 72 hours. Pay attention to your email to find out if your request has been approved."}</p>
                    </div>
                    <div>
                        <h2>{language === lenguaje.es ? "¿PUEDO SOLICITAR  VARIOS CRÉDITOS POR VARIAS CUENTAS DE VIDEOJUEGOS?" :"CAN I REQUEST MULTIPLE LOANS FOR MULTIPLE VIDEO GAME ACCOUNTS?"}</h2>
                        <p>{language === lenguaje.es ?  "No. El beneficio de Banca Gamer puede ser solicitado por el usuario una sola vez." : "No. The Gamer Loan benefit can be requested by the user only once."}</p>
                    </div>
                </div>
            </div>
        </section>
    </>
};