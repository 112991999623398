import { useState, useEffect } from "react";
import "../../components/InputText/InputText.css";
import { Loader } from "../../components/Loader/Loader";
import { useNavigate } from "react-router-dom";
import { ButtonBancaGamer } from "../../components/Button/Button";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

import { useLocation } from "react-router-dom";

export const ParadaisOptions = () => {
  const location = useLocation();
  useEffect(() => {
    const videoContainer = document.getElementById("videoContainer");
    if (location.pathname === "/Paradais") {
      console.log(videoContainer);
      videoContainer!.style.display = "none";
    }
    return () => {
      if (videoContainer !== null) {
        videoContainer.style.display = "block";
      }
    };
  });

  const [user, setUser] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [pass, setPass] = useState("");
  const [visibility, setVisibility] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const auth = getAuth();
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    signInWithEmailAndPassword(auth, user, pass)
      .then((userCredential) => {
        const user = userCredential.user;
        if (user.uid !== null) {
          setTimeout(() => {
            navigate("/GamesSettings");
          }, 700);
        }
      })
      .catch((error) => {
        setError(`${error.code} ${error.message}`);
      });
    setIsLoading(false);
  };
  return (
    <section style={{ background: "black" }}>
      <center>
        <img
          src="https://astrid-lab.com/public/logo-banca-gamer.png"
          alt="La banca gamer"
        />

        <h2>Login</h2>
        <form onSubmit={handleSubmit}>
          <div className="input-card">
            <label htmlFor="usuario">Usuario</label>
            <input
              className="input-items"
              type="email"
              name="usuario"
              id="usuario"
              minLength={5}
              value={user}
              onChange={(e) => setUser(e.target.value)}
            />
          </div>
          <div className="input-card" style={{ position: "relative" }}>
            <label htmlFor="acceso">acceso</label>
            <input
              className="input-items"
              minLength={5}
              type={visibility ? "text" : "password"}
              name="acceso"
              id="acceso"
              value={pass}
              onChange={(e) => setPass(e.target.value)}
            />
            <div
              className="row-buttons"
              style={{
                top: "12px",
                right: "0px",
                color: "black",
                cursor: "pointer",
              }}
            >
              <span
                className="material-symbols-outlined"
                onClick={() => setVisibility(!visibility)}
              >
                {visibility ? "visibility_off" : "visibility"}
              </span>
            </div>
          </div>
          {error === "" ? null : <p>Usuario o contraseña incorrecta</p>}
          <ButtonBancaGamer
            ruta={""}
            action={() => handleSubmit}
            label={"Ingresar"}
            tipo={2}
          ></ButtonBancaGamer>
        </form>
      </center>
      <Loader isLoading={isLoading} />
    </section>
  );
};
