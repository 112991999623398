import "./Loader.css";
import l1 from "../../assets/img/l1.png";
import l2 from "../../assets/img/l2.png";
import l3 from "../../assets/img/l3.png";
import l4 from "../../assets/img/l4.png";
import l5 from "../../assets/img/l5.png";
import { useEffect, useState } from "react";

type props = {
  isLoading: boolean
}
export const Loader = ({ isLoading }: props) => {
  const [imageCount, setImageCount] = useState(-1);

  const images: string[] = [l1, l2, l3, l4, l5];
  const displayedImages = images.slice(0, imageCount);

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (isLoading) {
      interval = setInterval(() => {
        setImageCount(prevCount => (prevCount + 1) % 6);
      }, 500); // Cambia este valor según el tiempo que desees entre cada imagen
    } else {
      setImageCount(-1); // Reinicia el contador cuando isLoading es false
    }

    return () => clearInterval(interval);
  }, [isLoading, images.length]);
  return !isLoading ? null :
    <div className="loader-container">
      <div className="surface">
        {displayedImages.map((image, index) => (
          <img key={index} src={image} alt="" />
        ))}
      </div>
    </div>;
};
