import { NavBar } from "../../components/NavBar/NavBar";
import { useContext, useEffect, useState } from "react";
import { Titular } from "../../components/Titular/Titular";
import { Row } from "../../components/Row/Row";
import { ButtonBancaGamer } from "../../components/Button/Button";
import { Dialog } from "../../components/Dialog/Dialog";
import { wow } from "../../data/ValorDeItems";
import InputText from "../../components/InputText/InputText";
import { useLocation } from "react-router-dom";
import { languageContext, lenguaje } from "../../Context/Context";
export const Warcraft = () => {
  const { language } = useContext(languageContext);
  // Estados calculadora
  const [cotizacion, setCotizacion] = useState(0);
  const [tipoBoton, setTipoBoton] = useState(3);
  const [openDialog, setOpendialog] = useState(false);
  // Estados juego
  const [level60Characters, setLevel60Characters] = useState(0);
  const [goldCoins, setGoldCoins] = useState(0);
  const [raidingEpicItems, setRaidingEpicItems] = useState(0);
  const [legendaryitems, setLegendaryitems] = useState(0);
  const [maxLevelProfessions, setMaxLevelProfessions] = useState(0);
  const [epicMounts, setEpicMounts] = useState(false);
  const nombreJuego = "Warcraft";

  const valorInicial =
    level60Characters +
      goldCoins +
      raidingEpicItems +
      legendaryitems +
      maxLevelProfessions < 0 ? wow.inicial : 0;

  const valorDeCuenta = valorInicial
    + (level60Characters * wow.maxLevelProfessions)
    + (goldCoins * wow.goldCoins)
    + (epicMounts ? wow.epicMounts : 0)
    + (raidingEpicItems * wow.raidingEpicItems)
    + (legendaryitems * wow.legendaryItems)
    + (maxLevelProfessions * wow.maxLevelProfessions);

  const handleClick = () => {
    let val: number = valorDeCuenta / 100;
    // addAnimation(val);
    val > cotizacion
      ? addAnimation(val)
      : val < cotizacion
        ? substracAnimation(val)
        : console.log("no cambiar");

    if (val > 0) {
      setTipoBoton(2);
    } else {
      setTipoBoton(3);
    }
  };

  // animacion de calculo de valores cuando suma
  const addAnimation = async (resultado: number) => {
    let result = cotizacion;
    const intervalId = setInterval(() => {
      if (result < resultado) {
        result = result + 3.33;
        setCotizacion(result);
      } else {
        clearInterval(intervalId);
        setCotizacion(resultado);
        console.log("Terminado");
      }
    }, 2);
  };
  // animacion de calculo de valores cuando resta
  const substracAnimation = async (resultado: number) => {
    let result = cotizacion;
    const intervalId = setInterval(() => {
      if (result > resultado) {
        result = result - 3.33;
        setCotizacion(result);
      } else {
        clearInterval(intervalId);
        setCotizacion(resultado);
        console.log("Terminado");
      }
    }, 2);
  };
  const enviarCuenta = () => {
    setOpendialog(true);
  };
  const location = useLocation();
  const [showScrollDown, setShowScrollDown] = useState(true);
  useEffect(() => {
    const onScroll = () => {
      const percentageScroll =
        ((window.scrollY / window.innerHeight) * 100) / 4;

      if (percentageScroll <= 75) {
        setShowScrollDown(true);
      } else {
        setShowScrollDown(false);
      }
    };
    window.addEventListener("scroll", onScroll);
    const videosCalculadora = document.getElementById("videoCalculadoras");
    if (location.pathname !== "/" && location.pathname !== "/Faqs") {
      videosCalculadora!.style.display = "block";
    }

    return () => {
      if (videosCalculadora !== null) {
        videosCalculadora.style.display = "none";
        window.removeEventListener("scroll", onScroll);
      }
    };
  });
  return (
    <>
      <section className="calculadora">
      <NavBar />
        <div className="form-container calculadora-padding">
          <div style={{ height: "100px" }}></div>
          <Titular cotizacion={cotizacion} titular={nombreJuego} />
          <span style={{ height: "60px" }}></span>
          <div className="grid">
            <InputText
              etiqueta={language === lenguaje.es ? "Personajes en nivel 60" : "CHARACTERS LEVEL 60"}
              valor={level60Characters}
              setValue={setLevel60Characters}
              aumento={1}
            />
            <InputText
              etiqueta={language === lenguaje.es ? "Monedas de oro" : "GOLD COINS"}
              valor={goldCoins}
              setValue={setGoldCoins}
              aumento={1}
            />
            <InputText
              etiqueta={language === lenguaje.es ? "Items épicos de Raid" : "EPIC ITEMS"}
              valor={raidingEpicItems}
              setValue={setRaidingEpicItems}
              aumento={1}
            />
            <InputText
              etiqueta={language === lenguaje.es ? "Items Legendarios" : "LEGENDARY ITEMS"}
              valor={legendaryitems}
              setValue={setLegendaryitems}
              aumento={1}
            />
            <InputText
              etiqueta={language === lenguaje.es ? "De profesiones al máximo nivel": "PROFESSION MAX LEVEL"}
              valor={maxLevelProfessions}
              setValue={setMaxLevelProfessions}
              aumento={1}
            />
            <div className="input-card">
              <label>{language === lenguaje.es ? "¿Tienes monturas épicas?" :"EPIC MOUNTS"}</label>
              <div className="outlined-radio">
                <input
                  type="radio"
                  id="epicMount"
                  name="epicMount"
                  value="epicMount"
                  checked={epicMounts}
                  onChange={() => {
                    setEpicMounts(true);
                  }}
                />
                <label htmlFor="epicMount">{language === lenguaje.es ? "Si" : "YES"}</label>
              </div>
              <div className="outlined-radio">
                <input
                  type="radio"
                  id="noEpicMount"
                  name="noEpicMount"
                  value="noEpicMount"
                  checked={!epicMounts}
                  onChange={() => {
                    setEpicMounts(false);
                  }}
                />
                <label htmlFor="noEpicMount">No</label>
              </div>
            </div>
          </div>
          <div className="calculadora-buttons">
            <ButtonBancaGamer
              ruta=""
              action={handleClick}
            label={language === lenguaje.es ? "CALCULAR VALOR" : "ESTIMATE NOW"}
              tipo={2}
            />
            <ButtonBancaGamer
              ruta=""
              action={enviarCuenta}
            label={language === lenguaje.es ? "ENVIAR" : "SEND"}
              tipo={tipoBoton}
            />
          </div>
          <div className="footer">COPYRIGHT © 2024 BANCO DEL PACÍFICO</div>
        </div>
          <Dialog openDialog={openDialog} closeDialog={setOpendialog} juego={nombreJuego} valor={cotizacion} />
      </section>
    </>
  );
};
