import "./GameOptions.css";
import { Link } from "react-router-dom";
import { useContext } from "react";
import imgControl from "../../assets/img/controller-Icon-white.png";
import {
  GameItemListContext,
  languageContext,
  lenguaje,
} from "../../Context/Context";

const colorValue = (values: number[]) => {
  const color =
    values[values.length - 1] > values[values.length - 2]
      ? "#6cff00"
      : "#ff2800";
  return color;
};
function arrowIcons(values: number[]) {
  const arrow =
    values[values.length - 1] > values[values.length - 2] ? "▲" : "▼";
  return arrow;
}
export const GameOptions = () => {
  const { language } = useContext(languageContext);

  const gameItemList = useContext(GameItemListContext);

  return (
      <div
        className="section-games-options"
      >
        {gameItemList.map((game, index) => (
          <Link
            to={game.destino}
            className="game-cards"
            style={{ backgroundImage: `url(${game.image})` }}
            key={index}
          >
            <div className="container-info">
              <img src={imgControl} alt="" />
              <div className="info-column">
                <span style={{ fontSize: "9px" }}>
                  {language === lenguaje.es ? "Videojuego" : "Videogame"}
                </span>
                <div className="game-title">{game.name}</div>
              </div>
              <span
                className="game-value"
                style={{ color: colorValue(game.value) }}
              >
                <b>{arrowIcons(game.value)}</b>$
                {game.value[game.value.length - 1]}
              </span>
            </div>
          </Link>
        ))}
      </div>
      
  );
};
