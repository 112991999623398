import { useContext, useEffect } from "react";
import imgControl from "../../assets/img/controller-Icon.png";
import { Row } from "../Row/Row";
import { languageContext, lenguaje } from "../../Context/Context";
type prop = {
  titular: string;
  cotizacion: number;
};
export const Titular = ({ titular, cotizacion }: prop) => {
  const { language } = useContext(languageContext);
  useEffect(() => {
    document.title = `Banca Gamer - ${titular}`;
  });
  return (
    <div className="game-title-sticky">
      <Row>
        <img src={imgControl} alt="" className="icon-control" />
        <div className="header-videojuego">
          <b>{language === lenguaje.es ? "Videojuego" : "Videogame"}</b>
          <h2>{titular}</h2>
        </div>
      </Row>
      <div className="value-container">
        <div className="value-estimate">
          <span className="valor-estimado">${cotizacion.toFixed(2)}</span>
        </div>
      </div>
    </div>
  );
};
